import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  host: { '[class]': 'componentClass' }
})
export class NotFoundComponent implements OnInit {
  componentClass = 'app-404';

  constructor() { }

  ngOnInit() {
  }

}
