/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./not-found.component";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-not-found", [], [[8, "className", 0]], null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i1.NotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var NotFoundComponentNgFactory = i0.ɵccf("app-not-found", i1.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
