import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private _apiData = new BehaviorSubject<Object>(null);
  public _apiData$ = this._apiData.asObservable();

  constructor() {}

  clearData() {
    this._apiData.next(null);
  }

  setData(data) {
    this._apiData.next(data);
  }
}
